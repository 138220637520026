import * as React from "react";
import { Padded } from "../system/layouts/Padded";
import { Spacer } from "../system/layouts/Spacer";
import { Container } from "../system/layouts/Container";
import { Button } from "../system/atoms/Button";
import { Tab, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { PrismAsync as SyntaxHighlighter } from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { Fragment } from "react";
import { CopyToClipboard } from "../CopyToClipboard";

import { Centered } from "../system/layouts/Centered";
import Statsig from "statsig-js";
import { StatsigGate, UserInfo } from "../../types";
import { Heading } from "../system/catalyst/heading";

interface UserInfoCardProps {
  token: string;
  userInfo: UserInfo;
  setUserInfo: React.Dispatch<React.SetStateAction<UserInfo | null>>;
}

export default function UserInfoPage({
  token,
  userInfo,
  setUserInfo,
}: UserInfoCardProps) {
  const addTokenHandler = async (account_id: number) => {
    await createToken(token, account_id);
    getUserInfoFromBackend(token).then(setUserInfo).catch(console.log);
  };

  const deleteTokenHandler = async (deletedToken: string) => {
    await deleteToken(token, deletedToken);
    getUserInfoFromBackend(token).then(setUserInfo).catch(console.log);
  };

  const addCreditsHandler = async (account_id: number) => {
    const res = await addCredits(token, account_id);
    window.open(res);
  };

  return (
    <Centered>
      <Padded>
        <AccountSummary
          account_id={userInfo.account_id}
          dollarCredits={userInfo.dollar_credits}
          creditsRemaining={userInfo.credits_remaining}
          tokens={userInfo.tokens}
          addTokenHandler={addTokenHandler}
          deleteTokenHandler={deleteTokenHandler}
          protectedTokens={[token]}
          addCreditsHandler={addCreditsHandler}
        />
      </Padded>
    </Centered>
  );
}

function AccountSummary({
  tokens,
  dollarCredits,
  creditsRemaining,
  account_id,
  addTokenHandler,
  deleteTokenHandler,
  protectedTokens,
  addCreditsHandler,
}: {
  tokens: string[];
  dollarCredits: number;
  creditsRemaining: number;
  account_id: number;
  addTokenHandler: any;
  deleteTokenHandler: any;
  protectedTokens: string[];
  addCreditsHandler: any;
}) {
  const examplePythonCode = `import lamini
lamini.api_key = "${tokens[0]}"

llm = lamini.Lamini("meta-llama/Meta-Llama-3.1-8B-Instruct")
print(llm.generate("""<|begin_of_text|><|start_header_id|>user<|end_header_id|>\n\nHow are you?<|eot_id|>\n<|start_header_id|>assistant<|end_header_id|>\n\n"""))`;
  const exampleRESTCode = `curl --location "https://api.lamini.ai/v1/completions" \\
--header "Authorization: Bearer ${tokens[0]}" \\
--header "Content-Type: application/json" \\
--data '{
    "model_name": "meta-llama/Meta-Llama-3.1-8B-Instruct",
    "prompt": "<|begin_of_text|><|start_header_id|>user<|end_header_id|>\\n\\nHow are you?<|eot_id|>\\n<|start_header_id|>assistant<|end_header_id|>\\n\\n"
}'`;
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <>
      <div className="col-start-3 col-span-6">
        <div className="flex justify-between">
          <Heading level={2}>API Tokens</Heading>
          <Button
            label="New Token"
            onClick={() => addTokenHandler(account_id)}
          />
        </div>
        <Spacer />
        <Container>
          <table className="divide-y w-full divide-neutral-600 table-fixed">
            <tbody className="divide-y divide-neutral-600 w-full">
              {tokens.map((token: string) => (
                <tr key={token} className="flex justify-between pl-4">
                  <td className="truncate py-2 pl-2 pr-12 text-base font-medium sm:pl-0 flex">
                    <p>{token}</p>
                    <CopyToClipboard text={token} />
                  </td>
                  <td className="flex ">
                    {!protectedTokens.includes(token) && (
                      <button
                        className="text-[#DC4C64] p-2  rounded hover:bg-[#2b2b2b]"
                        onClick={() => deleteTokenHandler(token)}
                      >
                        Revoke
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Container>
      </div>
      <Spacer />
      <Spacer />
      <div className="col-start-3 col-span-6">
        <div className="flex justify-between">
          <div>
            <Heading level={2}>Billing</Heading>
            <Spacer />
            <p>
              For more details, visit our{" "}
              <a
                href="https://lamini.ai/pricing"
                target="_blank"
                rel="noopener noreferrer"
                className="text-lamini-primary underline"
              >
                pricing page
              </a>
              .
            </p>
            <Spacer />
          </div>
          {Statsig.checkGate(StatsigGate.PRO_V2) && (
            <div className="p-2">
              <Button
                label="Buy Credits"
                onClick={() => addCreditsHandler(account_id)}
              ></Button>
            </div>
          )}
        </div>
        <Container>
          <div className="flex justify-between">
            <div className="flex flex-col">
              <div className="flex pb-2">
                <p>Credits Remaining</p>
              </div>
              <div>
                {Statsig.checkGate(StatsigGate.PRO_V2)
                  ? USDollar.format(dollarCredits)
                  : creditsRemaining}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

async function createToken(token: string, account_id: number) {
  const res = await fetch(
    (process.env.REACT_APP_API_URL || "") + "/v1/user/tokens/create",
    {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ account_id: account_id }),
    }
  );

  const data = await res.json();
  return data;
}

async function deleteToken(authToken: string, deletedToken: string) {
  const res = await fetch(
    (process.env.REACT_APP_API_URL || "") + "/v1/user/tokens/delete",
    {
      method: "POST",
      headers: {
        Authorization: "Bearer " + authToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: deletedToken }),
    }
  );

  return;
}

async function getUserInfoFromBackend(token: string) {
  const res = await fetch(
    (process.env.REACT_APP_API_URL || "") + "/v1/user/info",
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  const data = await res.json();
  return data;
}

export async function addCredits(token: string, account_id: number) {
  const res = await fetch(
    (process.env.REACT_APP_API_URL || "") + "/v1/stripe/initiate",
    {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ account_id: account_id }),
    }
  );

  return await res.text();
}
