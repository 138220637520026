import React from "react";

interface ContainerProps {
  children: React.ReactNode;
}

export const Container = ({ children }: ContainerProps) => {
  return (
    <div className="default bg-zinc-900 p-4 rounded w-full">{children}</div>
  );
};
