import * as React from "react";
import { Padded } from "../system/layouts/Padded";
import { Spacer } from "../system/layouts/Spacer";
import { Container } from "../system/layouts/Container";
import { Button } from "../system/atoms/Button";
import { Tab, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { PrismAsync as SyntaxHighlighter } from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { Fragment } from "react";
import { CopyToClipboard } from "../CopyToClipboard";
import { Centered } from "../system/layouts/Centered";
import Statsig from "statsig-js";
import { StatsigGate, UserInfo } from "../../types";
import { Heading } from "../system/catalyst/heading";
import { NavLink } from 'react-router-dom';

interface UserInfoCardProps {
  token: string;
  userInfo: UserInfo;
  setUserInfo: React.Dispatch<React.SetStateAction<UserInfo | null>>;
}

export default function UserInfoPage({
  token,
  userInfo,
  setUserInfo,
}: UserInfoCardProps) {
  const addTokenHandler = async (account_id: number) => {
    await createToken(token, account_id);
    getUserInfoFromBackend(token).then(setUserInfo).catch(console.log);
  };

  const deleteTokenHandler = async (deletedToken: string) => {
    await deleteToken(token, deletedToken);
    getUserInfoFromBackend(token).then(setUserInfo).catch(console.log);
  };

  const addCreditsHandler = async (account_id: number) => {
    const res = await addCredits(token, account_id);
    window.open(res);
  };

  return (
    <Centered>
      <Padded>
        <AccountSummary
          account_id={userInfo.account_id}
          dollarCredits={userInfo.dollar_credits}
          creditsRemaining={userInfo.credits_remaining}
          tokens={userInfo.tokens}
          addTokenHandler={addTokenHandler}
          deleteTokenHandler={deleteTokenHandler}
          protectedTokens={[token]}
          addCreditsHandler={addCreditsHandler}
        />
      </Padded>
    </Centered>
  );
}

function AccountSummary({
  tokens,
  dollarCredits,
  creditsRemaining,
  account_id,
  addTokenHandler,
  deleteTokenHandler,
  protectedTokens,
  addCreditsHandler,
}: {
  tokens: string[];
  dollarCredits: number;
  creditsRemaining: number;
  account_id: number;
  addTokenHandler: any;
  deleteTokenHandler: any;
  protectedTokens: string[];
  addCreditsHandler: any;
}) {
  const examplePythonCode = `import lamini
lamini.api_key = "${tokens[0]}"

llm = lamini.Lamini("meta-llama/Meta-Llama-3.1-8B-Instruct")
print(llm.generate("""<|begin_of_text|><|start_header_id|>user<|end_header_id|>\n\nHow are you?<|eot_id|>\n<|start_header_id|>assistant<|end_header_id|>\n\n"""))`;
  const exampleRESTCode = `curl --location "https://api.lamini.ai/v1/completions" \\
--header "Authorization: Bearer ${tokens[0]}" \\
--header "Content-Type: application/json" \\
--data '{
    "model_name": "meta-llama/Meta-Llama-3.1-8B-Instruct",
    "prompt": "<|begin_of_text|><|start_header_id|>user<|end_header_id|>\\n\\nHow are you?<|eot_id|>\\n<|start_header_id|>assistant<|end_header_id|>\\n\\n"
}'`;
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <>
      <div className="col-start-3 col-span-6">
        <Heading level={2}>Quick Start</Heading>
        <Spacer />
        <Container>
          <Tab.Group>
            <TabList className="border-b border-[#2b2b2b]">
              <Tab as={Fragment}>
                {({ selected }) => (
                  <button
                    className={
                      selected
                        ? "border-0 font-semibold px-8 py-1 text-lamini-primary border-b-2 border-lamini-primary hover:bg-[#2b2b2b]"
                        : "border-0 font-semibold px-8 py-1 text-lamini-primary border-b-2 border-[#2b2b2b] hover:bg-[#2b2b2b]"
                    }
                  >
                    Python
                  </button>
                )}
              </Tab>
              <Tab as={Fragment}>
                {({ selected }) => (
                  <button
                    className={
                      selected
                        ? "border-0 font-semibold px-8 py-1 text-lamini-primary border-b-2 border-lamini-primary hover:bg-[#2b2b2b]"
                        : "border-0 font-semibold px-8 py-1 text-lamini-primary border-b-2 border-[#2b2b2b] hover:bg-[#2b2b2b]"
                    }
                  >
                    Rest API
                  </button>
                )}
              </Tab>
            </TabList>
            <TabPanels className="p-4">
              <TabPanel>
                <div className="relative">
                  <SyntaxHighlighter style={a11yDark} language="python">
                    {examplePythonCode}
                  </SyntaxHighlighter>
                  <div className="absolute bottom-2 right-2">
                    <CopyToClipboard text={examplePythonCode} />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="relative">
                  <SyntaxHighlighter style={a11yDark} language="bash">
                    {exampleRESTCode}
                  </SyntaxHighlighter>
                  <div className="absolute bottom-2 right-2">
                    <CopyToClipboard text={exampleRESTCode} />
                  </div>
                </div>
              </TabPanel>
            </TabPanels>
          </Tab.Group>
        </Container>
      </div>
      <Spacer />
      <Spacer />
      <div className="col-start-3 col-span-6">
        <Heading level={2}>Examples</Heading>
        <Spacer />
        <div className="flex space-x-4">
            {/* Card 1 */}
            <a href="https://github.com/meta-llama/llama-cookbook/tree/main/3p-integrations/lamini/text2sql_memory_tuning" className="bg-zinc-900 p-4 border border-zinc-800 rounded-lg hover:bg-zinc-800 transition block w-full md:w-1/3" target="_blank" rel="noopener noreferrer">
              <div className="p-4">
                <h3 className="font-semibold text-lg text-lamini-primary">Text-to-SQL →</h3>
                <p>Tune Llama 3 for text-to-SQL and improve accuracy from 30% to 95%</p>
              </div>
            </a>
            {/* Card 2 */}
            <a href="https://github.com/lamini-ai/lamini-examples/blob/main/classify/classify-insurance.ipynb" className="bg-zinc-900 p-4 border border-zinc-800 rounded-lg hover:bg-zinc-800 transition block w-full md:w-1/3" target="_blank" rel="noopener noreferrer">
              <div className="p-4 ">
                <h3 className="font-semibold text-lg text-lamini-primary">Classifier →</h3>
                <p>Categorizes customer chat logs with an insurance company help desk.</p>
              </div>
            </a>
            {/* Card 3 */}
            <a href="https://www.lamini.ai/blog/llm-accuracy-from-factual-data" className="bg-zinc-900 p-4 border border-zinc-800 rounded-lg hover:bg-zinc-800 transition block w-full md:w-1/3" target="_blank" rel="noopener noreferrer">
              <div className="p-4 ">
                <h3 className="font-semibold text-lg text-lamini-primary">Factual QA →</h3>
                <p>Lamini user shares his journey from AI novice to achieving 95.8% accuracy with a fine-tuned LLM.</p>
              </div>
            </a>
        </div>
      </div>
    </>
  );
}

async function createToken(token: string, account_id: number) {
  const res = await fetch(
    (process.env.REACT_APP_API_URL || "") + "/v1/user/tokens/create",
    {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ account_id: account_id }),
    }
  );

  const data = await res.json();
  return data;
}

async function deleteToken(authToken: string, deletedToken: string) {
  const res = await fetch(
    (process.env.REACT_APP_API_URL || "") + "/v1/user/tokens/delete",
    {
      method: "POST",
      headers: {
        Authorization: "Bearer " + authToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: deletedToken }),
    }
  );

  return;
}

async function getUserInfoFromBackend(token: string) {
  const res = await fetch(
    (process.env.REACT_APP_API_URL || "") + "/v1/user/info",
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  const data = await res.json();
  return data;
}

export async function addCredits(token: string, account_id: number) {
  const res = await fetch(
    (process.env.REACT_APP_API_URL || "") + "/v1/stripe/initiate",
    {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ account_id: account_id }),
    }
  );

  return await res.text();
}
