import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import { Bars3Icon, UserIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import Statsig from "statsig-js";
import { GlobalDataContext, GlobalDataContextType } from "../GlobalDataContext";
import { StatsigGate, UserInfo } from "../types";
import { ThemeButton } from "../utils/theme";
import logo from "./logos/logo-offwhite.png";
import { Button } from "./system/catalyst/button";
import { Text } from "./system/catalyst/text";

export default function NavigationBar({ email }: UserInfo) {
  const globalData: GlobalDataContextType =
    useContext<GlobalDataContextType>(GlobalDataContext);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  let NavLinks = [
    {
      name: "Home",
      to: "/home",
      checkGate: null,
    },
    {
      name: "Tune",
      to: "/tune",
      checkGate: null,
    },
    {
      name: "Classify",
      to: "/classify",
      checkGate: null,
    },
    {
      name: "Docs",
      to: "https://docs.lamini.ai/",
    },
    {
      name: "Account",
      to: "/account",
    },
    {
      name: "Usage",
      to: "/usage",
      checkGate: StatsigGate.USAGE_TAB,
    },
  ];

  const UserMenuItems = [
    {
      name: "Admin",
      to: "/admin",
      checkGate: StatsigGate.ADMIN_CONSOLE,
      showLocal: globalData.config.environment == "dev",
    },
    {
      name: "Report an issue",
      to: "https://docs.google.com/forms/d/e/1FAIpQLSfrTQLajs4SIioN9xcWe2xxuqCXjR4YBolUE1J8lnuIP96ITA/viewform?usp=sf_link",
    },
    {
      name: "Log Out",
      to: "/logout",
    },
  ];

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <Disclosure as="nav" className="sticky top-0 z-50">
      <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 bg-lamini-light-background dark:bg-lamini-dark-background backdrop-blur-xl">
        <div className="flex h-16 items-center justify-between">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <img alt="Lamini" src={logo} className="h-4 w-auto" />
            </div>
            <div className="hidden sm:ml-10 sm:block">
              <div className="flex space-x-4">
                {NavLinks.map((link, index) =>
                  !link.checkGate || Statsig.checkGate(link.checkGate) ? (
                    <NavLink
                      to={link.to}
                      key={index}
                      className="rounded-md px-4 py-2 text-sm hover:bg-zinc-100 hover:dark:bg-zinc-800 hover:text-lamini-primary [&.active]:text-lamini-primary [&.active]:underline [&.active]:underline-offset-8"
                    >
                      {link.name}
                    </NavLink>
                  ) : null
                )}
              </div>
            </div>
          </div>
          <div className="hidden sm:ml-6 sm:block">
            <div className="flex items-center">
              <Menu as="div" className="relative ml-2">
                <MenuButton as="div">
                  <Button plain>
                    <UserIcon
                      className="stroke-2 stroke-zinc-100 hover:stroke-lamini-primary"
                      aria-hidden="true"
                    />
                  </Button>
                </MenuButton>
                <NavMenuItems>
                  <MenuItem>
                    <Text className="text-xs text-center py-2 -ml-2">
                      {email}
                    </Text>
                  </MenuItem>
                  {UserMenuItems.map((link, index) =>
                    !link.checkGate ||
                    Statsig.checkGate(link.checkGate) ||
                    link.showLocal ? (
                      <MenuItem key={index}>
                        <NavLink
                          to={link.to}
                          className="block px-4 py-2 text-sm text-zinc-300 hover:bg-zinc-100 hover:dark:bg-zinc-700"
                        >
                          {link.name}
                        </NavLink>
                      </MenuItem>
                    ) : null
                  )}
                </NavMenuItems>
              </Menu>
              {Statsig.checkGate(StatsigGate.THEME_SWITCHER) && <ThemeButton />}
            </div>
          </div>
          <div className="-mr-2 flex sm:hidden">
            {/* Mobile menu button */}
            <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-zinc-400 hover:bg-zinc-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
              <span className="absolute -inset-0.5" />
              <span className="sr-only">Open main menu</span>
              <Bars3Icon
                aria-hidden="true"
                className="block h-6 w-6 group-data-[open]:hidden"
              />
              <XMarkIcon
                aria-hidden="true"
                className="hidden h-6 w-6 group-data-[open]:block"
              />
            </DisclosureButton>
          </div>
        </div>
      </div>

      <DisclosurePanel className="sm:hidden fixed w-full bg-lamini-light-background dark:bg-lamini-dark-background">
        <div className="space-y-1 px-2 pb-3 pt-2">
          {NavLinks.map((link, index) =>
            !link.checkGate || Statsig.checkGate(link.checkGate) ? (
              <DisclosureButton
                as={NavLink}
                to={link.to}
                key={index}
                className="block rounded-md px-3 py-2 text-base font-medium text-zinc-300 hover:bg-zinc-700 hover:text-white"
              >
                {link.name}
              </DisclosureButton>
            ) : null
          )}
        </div>
        <div className="border-t border-zinc-700 pb-3 pt-4">
          <div className="mt-3 space-y-1 px-2">
            {UserMenuItems.map((item, index) => (
              <DisclosureButton
                as={NavLink}
                to={item.to}
                key={index}
                className="block rounded-md px-3 py-2 text-base font-medium text-zinc-400 hover:bg-zinc-700 hover:text-white"
              >
                {item.name}
              </DisclosureButton>
            ))}
            <Text className="text-center py-2">{email}</Text>
          </div>
        </div>
        {Statsig.checkGate(StatsigGate.THEME_SWITCHER) && <ThemeButton />}
      </DisclosurePanel>
    </Disclosure>
  );
}

const NavMenuItems = ({ children }: { children: React.ReactNode }) => (
  <MenuItems
    transition
    className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-lamini-muted-light dark:bg-lamini-muted-dark py-1 shadow-lg ring-1 ring-white ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
  >
    {children}
  </MenuItems>
);
