import * as React from "react";
import {
  Field,
  Description,
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { Input } from "../system/catalyst/input";
import { Button } from "../system/atoms/Button";
import { XMarkIcon } from "@heroicons/react/24/solid";
import ModelSelector, { ModelInfo } from "./../playground/ModelSelector";
import { useState, useRef, useEffect } from "react";
import FileInput from "./FileInput";
import { PLAYGROUND_MODELS } from "../playground/Playground";
import { TrainJob } from "../../types";
import fetchJob from "../../utils/fetchJob";
import demoFile from "../../assets/earnings-calls-demo.jsonl";
import { addCredits } from "../home/HomePage";
import { UserInfo } from "../../types";
interface TrainDialogProps {
  token: string;
  onClose: () => void;
  refresh: () => void;
  setActiveJob: (job: TrainJob) => void;
  userInfo: UserInfo;
}

export default function TrainDialog({
  token,
  onClose,
  refresh,
  setActiveJob,
  userInfo,
}: TrainDialogProps) {
  const [selectedModel, setSelectedModel] = React.useState<ModelInfo>(
    PLAYGROUND_MODELS[0]
  );
  const [useSampleData, setUseSampleData] = useState(true);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isCreditModalOpen, setIsCreditModalOpen] = React.useState(false);
  const fileInputRef = useRef(null);
  const [customModelName, setCustomModelName] = useState(
    `mt_${selectedModel.value.split("/")[1]}_${new Date()
      .toISOString()
      .slice(2, 10)
      .replace(/-/g, "")}`
  );
  const [isCustomModelNameEdited, setIsCustomModelNameEdited] = useState(false);

  useEffect(() => {
    if (!isCustomModelNameEdited) {
      setCustomModelName(
        `mt_${selectedModel.value.split("/")[1]}_${new Date()
          .toISOString()
          .slice(2, 10)
          .replace(/-/g, "")}`
      );
    }
  }, [selectedModel, isCustomModelNameEdited]);

  const addCreditsHandler = async (account_id: number) => {
    const res = await addCredits(token, account_id);
    window.open(res);
  };

  const templateForLine = (input: string, output: string, model: ModelInfo) => {
    let obj = {
      input: model.headerStart + input + model.headerEnd,
      output: output + model.outputEnd,
    };
    return obj;
  };

  const textToTemplates = (
    text: string,
    model: ModelInfo,
    repeatData: number,
    useTemplate: boolean,
    uploadBasePath: string
  ): any[] => {
    const lines = text.split("\n");
    let parts = [];

    for (let line of lines) {
      if (line) {
        for (let i = 0; i < repeatData; i++) {
          const lineObj = JSON.parse(line);
          let input = lineObj.question ? lineObj.question : lineObj.input;
          let output = lineObj.answer ? lineObj.answer : lineObj.output;

          if (useTemplate) {
            if (uploadBasePath === "azure") {
              parts.push(
                JSON.stringify(templateForLine(input, output, model)) + "\n"
              );
            } else {
              parts.push(templateForLine(input, output, model));
            }
          } else {
            let obj = {
              input: input,
              output: output,
            };
            if (uploadBasePath === "azure") {
              parts.push(JSON.stringify(obj) + "\n");
            } else {
              parts.push(obj);
            }
          }
        }
      }
    }
    return parts;
  };

  async function submitTrainingJob() {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    myHeaders.append("Content-Type", "application/json");

    let datasetBody = {
      id: "website",
      upload_base_path: "",
      args_name: "demo",
    };
    let response = await fetch(
      (process.env.REACT_APP_API_URL || "") + "/v1/dataset-info",
      {
        method: "POST",
        body: JSON.stringify(datasetBody),
        headers: myHeaders,
      }
    );
    let responseJson = await response.json();
    if (!response.ok) {
      if (response.status === 402) {
        setIsCreditModalOpen(true);
        return;
      }
    }

    const repeatData = 1;
    const useTemplate = true;
    const uploadBasePath = responseJson.hasOwnProperty("upload_base_path")
      ? responseJson["upload_base_path"]
      : "azure";
    let dataType = "";
    const getTrainingData = async (): Promise<string> => {
      if (useSampleData) {
        dataType = "sample";
        try {
          const response = await fetch(demoFile);
          return await response.text();
        } catch (err) {
          return "";
        }
      } else if (selectedFile != null) {
        dataType = "user";
        return await selectedFile.text();
      }
      return "";
    };

    const trainingParts = textToTemplates(
      await getTrainingData(),
      selectedModel,
      repeatData,
      useTemplate,
      uploadBasePath
    );

    const dataSize = trainingParts.length;
    const shuffledParts = trainingParts.sort((a, b) => 0.5 - Math.random());

    // upload data to server
    myHeaders.append("X-Client", "frontend");
    let datasetId = "";
    let datasetLocation = "";

    if (uploadBasePath === "azure") {
      const { BlobServiceClient } = await import("@azure/storage-blob");
      const sas_token = responseJson["sas_token"];
      const blobSasUrl =
        "https://laministorage.blob.core.windows.net/?" + sas_token;
      const blobServiceClient = new BlobServiceClient(blobSasUrl);
      datasetId = responseJson.hasOwnProperty("dataset_id")
        ? responseJson["dataset_id"]
        : "";
      const blob = new Blob(shuffledParts, { type: "text/plain" });
      const containerName = "training-data";
      const containerClient =
        blobServiceClient.getContainerClient(containerName);
      const blockBlobClient = containerClient.getBlockBlobClient(
        "platform/" + datasetId
      );
      const resp = await blockBlobClient.uploadBrowserData(blob);
    } else {
      let body = {
        id: "website",
        data: shuffledParts,
        upload_base_path: uploadBasePath,
      };

      response = await fetch(
        (process.env.REACT_APP_API_URL || "") + "/v1/local-data",
        {
          method: "POST",
          body: JSON.stringify(body),
          headers: myHeaders,
        }
      );
      responseJson = await response.json();
      if (!response.ok) {
        if (response.status === 402) {
          setIsCreditModalOpen(true);
          return;
        }
      }
      datasetId = responseJson["dataset_id"];
      datasetLocation = responseJson["dataset_location"];
    }

    // submit training job
    let trainingRequestBody = {
      id: "website",
      dataset_id: datasetId,
      model_name: selectedModel.value,
      upload_file_path: datasetLocation,
      finetune_args: {
        args_name: "demo",
        max_steps: 60,
        r_value: 32,
        learning_rate: 0.0003,
      },
      source: { id: "website", data_type: dataType, data_size: dataSize },
      custom_model_name: customModelName,
    };
    response = await fetch(
      (process.env.REACT_APP_API_URL || "") + "/v1/train",
      {
        method: "POST",
        body: JSON.stringify(trainingRequestBody),
        headers: myHeaders,
      }
    );
    responseJson = await response.json();
    if (!response.ok) {
      if (response.status === 402) {
        setIsCreditModalOpen(true);
        return;
      }
    }
    fetchJob(responseJson["job_id"], setActiveJob, () => {}, token);
    refresh();
    setSelectedFile(null);
    onClose();
  }

  return (
    <Transition appear show={true}>
      <Dialog
        as="div"
        className="relative z-10 focus:outline-none bg-lamini-light-background dark:bg-lamini-dark-background dark:text-[#ececec]"
        onClose={onClose}
      >
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-black/70">
          <div className="flex min-h-full items-center justify-center p-4">
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0 transform-[scale(95%)]"
              enterTo="opacity-100 transform-[scale(100%)]"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 transform-[scale(100%)]"
              leaveTo="opacity-0 transform-[scale(95%)]"
            >
              <DialogPanel className="w-[32rem] h-[38rem] rounded-xl bg-white/5 p-8 backdrop-blur-2xl  border border-[#393939]">
                <DialogTitle
                  as="h1"
                  className="text-base/8 pt-4 font-large text-white text-center"
                >
                  Memory Tune a model
                  <a href="#" onClick={onClose}>
                    <XMarkIcon className="w-5 fill-white/60 absolute top-6 right-6 size-6" />
                  </a>
                </DialogTitle>
                <div className="w-full max-w-md px-4">
                  <Field className="mt-10">
                    Step 1: Select a model
                    <div className="mt-2">
                      <ModelSelector
                        models={PLAYGROUND_MODELS}
                        selectedModel={selectedModel}
                        setSelectedModel={setSelectedModel}
                      />
                    </div>
                  </Field>
                  <Field className="mt-10">
                    Step 2: Select a tuning dataset
                    <div className="text-sm mt-2">
                      <input
                        type="radio"
                        id="demo"
                        name="data"
                        value="demo"
                        checked={useSampleData}
                        onChange={() => {
                          setUseSampleData(true);
                        }}
                      />
                      <label htmlFor="demo" className="ml-2">
                        {" "}
                        earnings-calls-demo.jsonl{" "}
                        <a
                          className="ml-2 text-lamini-primary underline whitespace-nowrap"
                          href={demoFile}
                        >
                          {" "}
                          Sample download
                        </a>
                        .
                      </label>
                    </div>
                    <div className="text-sm mt-2">
                      <input
                        type="radio"
                        id="custom"
                        name="data"
                        value="custom"
                        checked={!useSampleData}
                        onChange={() => {
                          setUseSampleData(false);
                        }}
                      />
                      <label htmlFor="custom" className="ml-2">
                        {" "}
                        Upload a new JSONL file
                      </label>
                    </div>
                    <Transition show={!useSampleData}>
                      <FileInput
                        setSelectedFile={setSelectedFile}
                        ref={fileInputRef}
                      />
                    </Transition>
                  </Field>
                  <Field className="mt-10">
                    Step 3: Enter custom model name
                    <div className="mt-2">
                      <Input
                        value={customModelName}
                        onChange={(e) => {
                          setCustomModelName(e.target.value);
                          setIsCustomModelNameEdited(true);
                        }}
                        className="w-full p-2 text-lg"
                      />
                    </div>
                  </Field>
                  <div className="text-center mt-10">
                    <Button
                      label="Tune the model"
                      onClick={submitTrainingJob}
                    />
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
        <Dialog
          open={isCreditModalOpen}
          onClose={() => setIsCreditModalOpen(false)}
          className="relative z-50"
        >
          <div className="fixed inset-0 flex w-screen items-center justify-center  p-4">
            <DialogPanel className="max-w-lg space-y-4 border bg-[#1a1a1a] border-[#393939] rounded-[16px] p-8">
              <DialogTitle className="font-bold">Out of Credits</DialogTitle>
              <Description>
                You've run out of credits. To continue using Lamini, please buy
                more credits.
              </Description>
              <div className="flex gap-4">
                <button onClick={() => setIsCreditModalOpen(false)}>
                  Cancel
                </button>
                <button
                  className="max-w-lg space-y-4 border bg-lamini-primary  border-[#393939] rounded-lg p-2"
                  onClick={() => {
                    addCreditsHandler(userInfo.account_id);
                    setIsCreditModalOpen(false);
                  }}
                >
                  Buy Credits
                </button>
              </div>
            </DialogPanel>
          </div>
        </Dialog>
      </Dialog>
    </Transition>
  );
}
