import * as React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useParams,
} from "react-router-dom";

import { initializeStatsig } from "./utils/statsig";

import { Playground } from "./components/playground/Playground";
import { MemRAGPlaygroundController } from "./components/playground/MemRAGPlaygroundController";
import Login from "./components/login/Login";
import NavigationBar from "./components/NavigationBar";
import TrainPage from "./components/train/TrainPage";
import SharePage from "./components/SharePage";
import { UserContext, UserContextType } from "./components/user/UserContext";
import UserInfoPage from "./components/home/HomePage";
import AccountPage from "./components/user/AccountPage";
import { StatsigGate, UserInfo } from "./types";
import UsagePage from "./components/user/UsagePage";
import ShareTrainJobPage from "./components/train/ShareTrainJobPage";
import AdminPage from "./components/admin/AdminPage";
import Statsig from "statsig-js";
import { useContext } from "react";
import { GlobalDataContext, GlobalDataContextType } from "./GlobalDataContext";
import Classifier from "./classifier/Classifier";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { NewClassifierProject } from "./classifier/components/NewClassifierProject";
import { ThemeWrapper, useTheme } from "./utils/theme";
import RAGChatExplorer from "./components/rag/RAGChatExplorer";
import { PDFLandingPage } from "./components/playground/PDFLandingPage";

const queryClient = new QueryClient();
const defaultProfile: { token: string | null } = {
  token: null,
};

const RedirectWithParam = () => {
  const { trainJobID } = useParams(); // Get the trainJobID from the current route
  return <Navigate to={`/tune/${trainJobID}`} replace />;
};

function AppProvider({ token }: { token: string }) {
  const globalData: GlobalDataContextType =
    useContext<GlobalDataContextType>(GlobalDataContext);
  const [userInfo, setUserInfo] = React.useState<UserInfo | null>(null);
  React.useEffect(() => {
    if (globalData.loading) {
      return;
    }
    getUserInfoFromBackend(token, globalData)
      .then(setUserInfo)
      .catch(console.error);
  }, [globalData]);

  if (globalData.loading) {
    return <div>Loading...</div>;
  }

  if (userInfo === null) {
    return null;
  }

  return (
    <Router>
      <ThemeWrapper>
        <NavigationBar {...userInfo} />
        <Routes>
          <Route
            path="/home"
            element={
              <UserInfoPage
                token={token}
                userInfo={userInfo}
                setUserInfo={setUserInfo}
              />
            }
          />
          <Route
            path="/account"
            element={
              <AccountPage
                token={token}
                userInfo={userInfo}
                setUserInfo={setUserInfo}
              />
            }
          />
          {Statsig.checkGate(StatsigGate.USAGE_TAB) && (
            <Route path="/usage" element={<UsagePage token={token} />} />
          )}
          {Statsig.checkGate(
            StatsigGate.ADMIN_CONSOLE || globalData.config.environment == "dev"
          ) && <Route path="/admin" element={<AdminPage token={token} />} />}
          <Route
            path="/documentation"
            Component={() => {
              window.location.replace("https://lamini-ai.github.io");
              return null;
            }}
          />
          {Statsig.checkGate(StatsigGate.MEMORY_RAG_UI) && (
            <>
              <Route
                path="/playground"
                element={
                  <MemRAGPlaygroundController
                    token={token}
                    userInfo={userInfo}
                  />
                }
              />
              <Route
                path="/pdf"
                element={<Navigate to="/playground" replace />}
              />
            </>
          )}
          <Route path="/train" element={<Navigate to="/tune" replace />} />
          <Route
            path="/tune"
            element={<TrainPage token={token} userInfo={userInfo} />}
          />
          <Route path="/train/:trainJobID" element={<RedirectWithParam />} />
          <Route
            path="/tune/:trainJobID"
            element={<ShareTrainJobPage token={token} userInfo={userInfo} />}
          />
          <Route
            path="/share"
            element={<SharePage token={token} userInfo={userInfo} />} // TODO: Deprecate this
          />
          <Route path="/classify" element={<Classifier />} />
          <Route
            path="/classify/:classifierProjectId"
            element={<Classifier />}
          />
          <Route path="/classify/new" element={<NewClassifierProject />} />
          <Route
            path="/logout"
            Component={() => {
              window.location.replace(
                (process.env.REACT_APP_API_URL || "") + "/v1/auth/logout"
              );
              return null;
            }}
          />
          <Route path="/" element={<Navigate to="/home" replace />} />
        </Routes>
      </ThemeWrapper>
    </Router>
  );
}

export default function App() {
  const [profile, setProfile] = React.useState(defaultProfile);
  const [isQueryingForToken, setIsQueryingForToken] = React.useState(true);
  const context = { profile: profile, setProfile: setProfile };
  React.useEffect(() => {
    if (!profile.token) {
      getConfigTokenFromBackend()
        .then((data) => {
          setProfile(data);
          setIsQueryingForToken(false);
        })
        .catch(console.log);
    }
  }, []);

  useTheme();

  return (
    <UserContext.Provider value={context}>
      {profile.token ? (
        <QueryClientProvider client={queryClient}>
          <AppProvider token={profile.token} />
        </QueryClientProvider>
      ) : (
        <Login isQueryingForToken={isQueryingForToken} />
      )}
    </UserContext.Provider>
  );
}

async function getUserInfoFromBackend(
  token: string,
  globalData: GlobalDataContextType
) {
  const res = await fetch(
    (process.env.REACT_APP_API_URL || "") + "/v1/user/info",
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  const data = await res.json();
  try {
    await initializeStatsig(
      data.email,
      globalData.config.statsig_client_public_key,
      globalData.config.environment == "dev"
    );
  } catch (e) {}
  return data;
}

async function getConfigTokenFromBackend() {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), 3000);
  let data = {
    is_granted: false,
    token: null,
    credentials: null,
  };
  try {
    const res = await fetch(
      (process.env.REACT_APP_API_URL || "") + "/v1/auth/config_token",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        signal: controller.signal,
      }
    );
    data = await res.json();
  } catch (e) {}
  clearTimeout(timeoutId); // Clear the timeout if the request is successful
  return data;
}
